import React from 'react';
import me from './me.jpg';
import { SocialIcon } from 'react-social-icons';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={me} className="App-logo" alt="logo" />
        <div>
          <SocialIcon id="linkedIn" url="https://linkedin.com/in/mark-the-butler"/>
          <SocialIcon id="github" url="https://github.com/mark-the-butler"/>
        </div>
      </header>
    </div>
  );
}

export default App;
